<template>
    <div class="google-login-button" :class="{ isDisabled: isDisabled() }" @click.stop="startLoginWithSso()">
        <template v-if="!isLoading">
            <google-icon class="google-login-button__icon"></google-icon>
            <div class="google-login-button__title">{{ consts.googleButtonText }}</div>
        </template>
        <template v-else>
            <div class="google-login-button--loading"><font-awesome-icon :icon="['far', 'spinner']" pulse></font-awesome-icon></div>
        </template>
    </div>
</template>

<script>
import langConsts from '@/lang/en';
import { mapState } from 'vuex';

export default {
    name: 'GoogleLoginButton',
    components: {
        GoogleIcon: () => import('@/modules/views/icons/GoogleIcon')
    },
    props: {
        ssoLoginUrl: {
            type: String,
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            consts: langConsts
        };
    },
    computed: {
        ...mapState('login', ['isLoading'])
    },
    methods: {
        startLoginWithSso() {
            if (this.ssoLoginUrl) {
                window.location.assign(this.ssoLoginUrl);
            }
        },
        isDisabled() {
            return this.ssoLoginUrl === undefined;
        }
    }
};
</script>

<style lang="scss">
.google-login-button {
    align-items: center;
    background: $white;
    border-radius: 0;

    /* Popup */
    box-shadow: 0 0 0.6rem $nevada-lighter-transparent;
    cursor: pointer;
    display: flex;
    height: $input-height;
    margin: auto;
    width: $input-width;
    &--loading {
        margin: auto;
    }
    &.isDisabled {
        opacity: 0.65;
        pointer-events: none;
    }
    &__title {
        color: $dove-gray;
        font-weight: 600;
        margin-right: auto;
        text-align: left;
    }

    &__icon {
        border-radius: 0;
        height: 1.3rem;
        margin-left: auto;
        margin-right: 0.4rem;
        width: 1.3rem;
    }
}
</style>
